import React from 'react';
import ThankyouLP from 'components/thankyou-lp';

export default function ThankyouLPPage() {
  return (
    <main>
      <ThankyouLP />
    </main>
  );
}
