import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';
import Button from 'button';
import { navigate } from 'gatsby';
import s from './thankyou-lp.module.scss';

export default function ThankYouLP() {
  return (
    <Container className={s.root}>
      <div className={s.wrapper}>
        <Headline primary className={`${s.wild}`} h1 wild html="Yay! You're signed up!" />
        <div className={s.flex}>
          <Img className={s.img} src="thank-you" />
					<div className={s.textWrap}>
						<p className={s.text}>The Manifesting Breakthrough Challenge Begins on May 19th! </p>
						<div className={s.joinWrap}>
							<p className={s.joinText}>JOIN OUR CHALLENGE <span className={s.arrow}>L</span></p>
							<p className={s.joinText}>FACEBOOK GROUP</p>
							<Button href="https://www.facebook.com/groups/213077505851460/?ref=bookmarks" className={s.button}>I'D LOVE TO</Button>
						</div>
					</div>
        </div>
      </div>
    </Container>
  );
}
